import { Box, Grid, GridItem, Heading, HStack, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react'

const SalesPage = () => {

    const [sales, setSales] = useState();
    const [isLoading, setIsLoading] = useState(true);


  const MXNCurrency = (number) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(number);
  }

    useEffect(() => {
        fetch((process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? `http://localhost:8000/api/get_sales_2` : `/api/get_sales_2`)
            .then((res) => res.json())
            .then(({ data }) => {
                setSales(data);
                setIsLoading(false);
            })
    }, []);


    return (
        <VStack mt={3} w='100%'>
            <Heading>{ moment().format('MMMM').toUpperCase() } {moment().format('Y')}</Heading>
            {
                !isLoading
                    ? <>
                        <Table>
                            <Tbody>
                                {
                                    sales.map((sale) => (
                                        <>
                                            <Tr>
                                                <Th borderWidth={1} borderColor='black' bgColor='#0066CC' color='white'>Oficina</Th>
                                                <Th borderWidth={1} borderColor='black' bgColor='#0066CC' color='white'>Venta Ayer</Th>
                                                <Th borderWidth={1} borderColor='black' bgColor='#0066CC' color='white'>Venta diaria</Th>
                                                <Th borderWidth={1} borderColor='black' bgColor='#0066CC' color='white'>Venta Prom. Diario</Th>
                                                <Th borderWidth={1} borderColor='black' bgColor='#0066CC' color='white'>PPTO Diario</Th>
                                                <Th borderWidth={1} borderColor='black' bgColor='#0066CC' color='white'>% Cumpliemiento Diario</Th>
                                            </Tr>
                                            <Tr>
                                                <Td rowSpan={3} borderWidth={1} borderColor='black' fontWeight='bold'>{sale['Oficina']}</Td>
                                                <Td fontSize={24} rowSpan={3} borderWidth={1} borderColor='black' fontWeight='bold'>{MXNCurrency(sale['Venta Ayer'])}</Td>
                                                <Td fontSize={24} borderWidth={1} borderColor='black'>{MXNCurrency(sale['Venta Diaria'])}</Td>
                                                <Td fontSize={24} borderWidth={1} borderColor='black'>{MXNCurrency(sale['Venta Prom Diario'])}</Td>
                                                <Td fontSize={24} borderWidth={1} borderColor='black'>{MXNCurrency(sale['PPTO Diario'])}</Td>
                                                <Td fontSize={24} borderWidth={1} borderColor='black'>{sale['%Cumplimiento Diario'].toFixed(2)}%</Td>
                                            </Tr>
                                            <Tr>
                                                <Th borderWidth={1} borderColor='black' bgColor='#BF504E' color='white'>Venta Acumulado</Th>
                                                <Th borderWidth={1} borderColor='black' bgColor='#BF504E' color='white'>Presupuesto Acumulado</Th>
                                                <Th borderWidth={1} borderColor='black' bgColor='#BF504E' color='white'>Diferencia de Venta Acumulada</Th>
                                                <Th borderWidth={1} borderColor='black' bgColor='#7F64A1' color='white'>Venta Proyectada</Th>
                                            </Tr>
                                            <Tr>
                                                <Td fontSize={24} borderWidth={1} borderColor='black'>{MXNCurrency(sale['Venta Acumulado'])}</Td>
                                                <Td fontSize={24} borderWidth={1} borderColor='black'>{MXNCurrency(sale['Presupuesto Acumulado'])}</Td>
                                                <Td fontSize={24} borderWidth={1} borderColor='black'>{MXNCurrency(sale['Diferencia de Venta Acumulada'])}</Td>
                                                <Td fontSize={24} borderWidth={1} borderColor='black'>{MXNCurrency(sale['Venta Proyectada'])}</Td>
                                            </Tr>
                                        </>
                                    ))
                                }
                            </Tbody>
                        </Table>
                    </>
                    : (
                        <Box w='100%' h='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            <Spinner size='xl' thickness='6px' color='red.500' />
                            <Text>Cargando</Text>
                        </Box>
                    )
            }
        </VStack>
    )
}

export default SalesPage