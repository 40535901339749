import { Divider, Flex, Image } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import CalendarByUser from '../components/CalendarByUser';
import DetailedCalendar from '../components/DetailedCalendar';
import ExecutivesResults from '../components/ExecutivesResults';
import LogoRedex from '../assets/imgs/logo-rex.png';
import SalesPage from './SalesPage';

const SACPage = () => {
  const [currentView, setCurrentView] = useState(0);

  useEffect(() => {
    let time = (currentView === 2) ? 60000 : 20000;

    setTimeout(() => {
      if(currentView < 3) {
        setCurrentView(currentView + 1);
      } else {
        setCurrentView(0);
      }
    }, time);

  }, [currentView]);

  return (
    <Flex p={5} flexDirection='column' alignItems='center' w='100vw' h='100vh'>
      <Image my={2} src={LogoRedex} w='300px' />
      <Divider />
      {
        currentView === 0
          ? <CalendarByUser />
          : currentView === 1 
            ? <DetailedCalendar /> 
            : currentView === 2 ? <ExecutivesResults /> : <SalesPage />
      }
    </Flex>
  )
}

export default SACPage